import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://nodejs-api-g5d.me-south-1.elasticbeanstalk.com/api/v1/aa"
      : "http://localhost:3500/api/v1/aa",
});

export default instance;
